import React from 'react';
import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';
import img1 from "../images/look-partner.svg";
import HeaderPartners from '../components/common/HeaderPartners';
import HireForm from '../components/common/hireForm';
import { Helmet } from "react-helmet";
import '../assets/sass/partners.scss';
const Help = () => {
    return(
      <Layout>
        <HeaderPartners />
        <Helmet>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />       
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
            <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
            <meta name="description" content="Frequently Asked Questions About LaundryToGo Service." />
            <title>LaundryToGo - Partnership</title>
        </Helmet>
      <div id="wrapper" style={{color: "#000"}}>
        {/* Start */}
        <div className="help-wrapper" style={{background: "#fff",color: "#000"}}>
            <div className="container partners-container" style={{padding: "40px 20px"}}>
                    <div className="row">
                        <div className="col-md-5 col-sm-12 partners-image-wrapper">
                            <h1>Looking for an opportunity?</h1>
                            <p>Get paid by picking up and doing laundry</p>
                            <img src={img1} alt ="Partner Image" />
                        </div>
                        <div className="col-md-7 answer">
                            <HireForm />
                        </div>
                    </div>
            </div>
        </div>
        {/* End */}
        </div>
        <Footer bg={"white"} />
  </Layout>
    )
}

export default Help;